import React from "react";
import "./styles.scss";

const Button = ({ withIcon, icon, text, onClick, isBlue, className }) => {
  return (
    <button
      className={`button cursor_pointer ease ${
        isBlue ? "blue_button" : "green_button"
      } ${className}`}
      onClick={onClick}
    >
      {withIcon && <span className="mr_10">{icon}</span>}
      {/* <a href="mailto:henry@cokitchen.ng"> */}
      <label className="text_22 white noirpro_reg cursor_pointer">{text}</label>
      {/* </a> */}
    </button>
  );
};

export { Button };
