import React, { useState } from "react";
import Logo from "../../assets/images/logo.png";
import { Button } from "../../components/buttons";
import Input from "../../components/input";
import "./styles.scss";
const GetInTouch = ({ onClick }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  return (
    <main className="flex_column_center m_auto">
      <div className="flex_column_center">
        <a onClick={onClick}>
          <img src={Logo} alt="logo" className="logo mb_25 cursor_pointer" />
        </a>
      </div>
      <section className="flex_column_start full_width tab_column">
        <h3 className="noirpro_reg blue mb_25 text_center_md text_45">
          Get In Touch.
        </h3>
        <h4>careers@cokitchen.ng</h4>
        {/* <Input
          placeholder="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e)}
          required
          containerClass="third_width_desktop mb_25"
        />
        <Input
          placeholder="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e)}
          required
          containerClass="third_width_desktop mb_25"
        />

        <Input
          placeholder="Telephone"
          value={phone}
          onChange={(e) => setPhone(e)}
          required
          containerClass="third_width_desktop mb_25"
          type="tel"
        />
        <Input
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e)}
          required
          containerClass="third_width_desktop mb_25"
          type="email"
        /> */}

        {/* <Button isBlue text="Send Message" className="mb_25" /> */}
      </section>
    </main>
  );
};
export default GetInTouch;
