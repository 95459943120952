import React, { useState } from "react";
import Logo from "../../assets/images/logo.png";
import { CloudImage } from "../../assets/icons";
import { Button } from "../../components/buttons";
import GetInTouch from "../GetInTouch";
import "./styles.scss";

const HomePage = () => {
  const [showIndex, setshowIndex] = useState(true);
  return (
    (showIndex && (
      <main className="flex_column_start">
        <div className="flex_column_center">
          <img src={Logo} alt="logo" className="logo cursor_pointer" />
        </div>
        <section className="flex_row_top full_width tab_column m_auto">
          <div className="">
            <CloudImage />
          </div>

          <div className="mt_60 second_width_desktop tab_column mt_md_0">
            <h3 className="noirpro_reg blue mb_45 text_center_md text_45">
              Delivering Convenience with Infrastructure & Technology.
            </h3>
            <Button
              isBlue
              text="Get in touch"
              className="mb_45"
              onClick={() => setshowIndex(false)}
            />
          </div>
        </section>
      </main>
    )) || <GetInTouch onClick={() => setshowIndex(true)} />
  );
};
export default HomePage;
